import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
// import multiMonthPlugin from '@fullcalendar/multimonth'
import frLocale from '@fullcalendar/core/locales/fr';
import variables from '../../scss/app.scss'

function getEvents(fetchInfo, successCallback, failureCallback){ // see https://fullcalendar.io/docs/events-function
  let startCal = new Date(fetchInfo.startStr).getTime() / 1000
  let endCal = new Date(fetchInfo.endStr).getTime() / 1000
  let filtered_events = all_events.filter(ev => {
    if(ev.dateStart<startCal || ev.dateStart>endCal) return false // only show events requested by the date range from FullCalendar
    if(ev.hidden==true) return false // only show events not excluded by the filter menu
    return true
  });
  filtered_events = filtered_events.map(ev=>{
    return {
      id: ev.id,
      title: ev.title,
      start: new Date(ev.dateStart*1000),
      end: new Date(ev.dateStop*1000),
      backgroundColor: variables[ev.theme.name.toLowerCase().replace('é', 'e')+'Color'],
      borderColor: variables[ev.theme.name.toLowerCase().replace('é', 'e')+'Color'],
      extendedProps: {
        categoryIcon: ev.categoryIcon,
        themeCanonical: ev.themeCanonical,
        cancelled: ev.cancelled,
        full: ev.full,
      }
    }
  })
  successCallback(filtered_events)
} 

let calendarEl = document.getElementById('calendarContainer')
let initialView = calendarListBtn.hasAttribute('selected')?'listMonth':'dayGridMonth' // in front index.html, the calendar may not be ready when the click event was triggered on the button.
// if(!(['dayGridMonth', 'listMonth'].includes(initialView))) initialView = 'dayGridMonth'
window.fullCalendar = new Calendar(calendarEl, {
  plugins: [ dayGridPlugin, listPlugin],
  initialView: initialView,
  // aspectRatio: 1.5,

  // OPTION 1 :
  // height: "auto",
  // dayMaxEventRows: 6, //this will avoid inconsistent week rows heights
  // OPTION 2 :
  height: "100%",
  minHeight: '200px',
  dayMaxEventRows: true, //this will avoid inconsistent week rows heights

  eventDisplay: 'block',
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    // right: 'dayGridMonth,listMonth'
    right: ''
  },
  events: getEvents,
  locale: frLocale,
  views: {
    listMonth:{
      noEventsContent:'Aucun évènement ? Vos filtres de recherche sont peut être trop restricifs, ou sinon, naviguez vers les mois suivants.'
    }
  },
  // noEventsMessage: 'toto',
  eventDidMount: function(info) {
    // Put here event-specific operations to perform when each event is displayed.
    
    // create the category icon
    let catImage = document.createElement("img");
    catImage.src = info.event.extendedProps.categoryIcon
    catImage.classList.add('cat-image')
      // replace list view fullcalendar dots by a div with the category icon
    let listGraphicsTd = info.el.querySelector('.fc-list-event-graphic')
    if(listGraphicsTd){
      // listGraphicsTd.querySelector('.fc-list-event-dot').remove()
      let theCircle = document.createElement("div");
      theCircle.classList.add('event-list-circle')
      theCircle.classList.add(info.event.extendedProps.themeCanonical) //use this to get the theme color.
      theCircle.append(catImage)
      listGraphicsTd.append(theCircle)
    } else {
      //add category icon to month view
      catImage.classList.add('cat-image-month-view')
      let prependto = info.el.querySelector('.fc-event-time')
      if(prependto) prependto.prepend(catImage)
    }

    // make the event clickable
    info.el.style.cursor = 'pointer'
    info.el.setAttribute('onclick', 'ajaxEvenementModale('+info.event.id+')')
    info.el.setAttribute('data-toggle', 'modal')
    info.el.setAttribute('data-target', '.bd-example-modal-lg')

    // add cancelled css class if cancelled event
    if(info.event.extendedProps.cancelled){
      info.el.classList.add('cancelled')
    }
    if(info.event.extendedProps.full){
      info.el.classList.add('full')
    }
  },
  // viewDidMount: function(arg) {
  //   // set cookie at each view change to remember month/list preference
  //   // cookiesManagement.setCookie('front_calendarview_type', arg.view.type , 365)
  //   // console.log(calendarListBtn.hasAttribute('selected'))
  // }
});
window.fullCalendar.render();